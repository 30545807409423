<template>
  <div>
    <b-container fluid class="applicant-onboard user-onboard customized-form mt-5 pt-5">
      <div class="header">
        <div>
          <h4 class="user-welcome pb-4">Welcome, User</h4>
          <div class="profile-box">
            <div class="profile-box-header">
              <h4 class="profile-box-title">
                Improve <br />
                your profile
              </h4>
              <div>
                <radial-progress-bar :diameter="90" :stroke-width="8" :inner-stroke-width="10" inner-stroke-color="#fff" start-color="#6244e5" stop-color="#6244e5" :completed-steps="formComplete" :total-steps="totalSteps">
                  <p class="f-primary mb-0 font-weight-bold">{{ formComplete }}%</p>
                </radial-progress-bar>
              </div>
            </div>
            <div class="profile-box-content">
              <p>Add the following data to your profile to reach 100% and maximise your chance of getting recognised by employers</p>

              <div class="profile-box-step">
                <p :class="cvUploaded || cv ? 'f-light strike-through' : 'fpn-b text-dark'">Add Cv</p>
                <!-- <p class="f-light strike-through">
                  Upgrade and add video profiles
                </p> -->
                <p :class="exp[0].designation && exp[0].description ? 'f-light strike-through' : 'fpn-b text-dark'">Add Experiance</p>
                <p :class="edu[0].degree && edu[0].university_name ? 'f-light strike-through' : 'fpn-b text-dark'">Add Education</p>
                <p :class="social.facebook || social.twitter || social.instagram || social.linkedin ? 'f-light strike-through' : 'fpn-b text-dark'">Add Social Link</p>
              </div>
            </div>
          </div>
        </div>
        <div class="step-heading">
          <figure>
            <img src="@/assets/app-icons/cv.svg" class="img-fluid pr-3" alt="cv-icon" width="50px" />
          </figure>
          <div class="pt-2">
            <h5 class="step-number">STEP 1</h5>
            <h4 class="step-name">Upload your CV</h4>
          </div>
        </div>
        <div>
          <!-- <figure class="pt-5">
            <img src="@/assets/linkedin.png" class="img-fluid" width="220px" />
          </figure> -->
          <div class="app-grid">
            <div class="resume-box">
              <div class="resume-doc" v-b-tooltip.hover :title="cvName">
                <img src="@/assets/app-icons/pdf.svg" class="img-fluid mr-3" alt="pdf-icon" width="20px" />
                <a v-if="cv && cvName != 'Upload Your Cv'" :href="cv" target="_blank">{{ cvName }}</a>
                <span v-if="!cv && cvName == 'Upload Your Cv'">Upload Your Cv</span>
              </div>
              <div class="resume-action px-3" @click="onDeleteResume">
                <img src="@/assets/app-icons/delete.svg" class="img-fluid" alt="delete-icon" width="20px" style="cursor: pointer" />
              </div>
            </div>
            <div class="custom-upload pt-4 pt-xl-0 pt-lg-0">
              <label for="file-upload" class="btn reupload-btn">
                <img src="@/assets/app-icons/upload.svg" class="img-fluid mr-3" alt="upload-icon" width="20px" />

                <!-- {{ cv && fileSelected ? "File Selected" : "UPLOAD" }} -->
                {{ !cv ? "UPLOAD" : "RE-UPLOAD" }}
              </label>
              <input onclick="this.value = null" id="file-upload" type="file" @change="onFileChange" />
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="border-bottom py-4 px-2">
          <div class="sub-heading">
            <label>WORK EXPERIENCE</label>
          </div>
          <div class="user-form">
            <div v-for="(e, counter) in exp" :key="counter" class="row">
              <div class="col-md-10">
                <div class="user-work-form">
                  <div>
                    <b-form-input placeholder="Organisation name" v-model="e.org_name"> </b-form-input>
                    <div v-if="submit && (!e.org_name || String(e.org_name).length == 0)" class="error-msg">
                      <small>Organisation name is required</small>
                    </div>
                  </div>
                  <div class="grid-date">
                    <div class="date-change">
                      <date-picker v-model="e.dateRange.startDate" @change="validateExpDate(counter)" placeholder="Start Date" class="custom-date" :disabled-date="(date) => date > dateCondition"></date-picker>
                      <div v-if="submit && (!e.dateRange.startDate || String(e.dateRange.startDate).length == 0)" class="error-msg">
                        <small>Start Date is required</small><br />
                        <!-- <small>{{
                      e.dateValid ? "" : "Enter Valid Date Range"
                    }}</small> -->
                      </div>
                      <div v-if="submit && !e.validDate" class="error-msg">
                        <small>Enter Valid Date Range</small>
                      </div>
                    </div>
                    <!-- <div class="error-msg" :class="classes">
                      <span>{{ errors[0] }}</span>
                      <small>{{
                        e.dateValid ? "" : "Enter Valid Date Range"
                      }}</small>
                    </div>
                  </ValidationProvider> -->
                    <!-- <ValidationProvider
                    ref="expEndDate"
                    name="endDate"
                    rules="required"
                    v-slot="{ errors, classes }"
                  > -->
                    <!-- <datepicker
                  v-model="e.dateRange.endDate"
                  placeholder="End Date"
                  class="custom-date"
                  :disabled-date="(date) => date > dateCondition"
                  @input="
                    validateExpDate(
                      e.dateRange.startDate,
                      e.dateRange.endDate,
                      counter
                    )
                  "
                ></datepicker> -->
                    <div class="grid-end-date">
                      <div>
                        <div v-if="e.till_present == 'N'" class="date-change">
                          <div>
                            <date-picker :disabled-date="(date) => date > dateCondition" v-model="e.dateRange.endDate" @change="validateExpDate(counter)" placeholder="End Date" type="date" class="custom-date end-date"></date-picker>
                            <div v-if="submit && (!e.dateRange.endDate || String(e.dateRange.endDate).length == 0)" class="error-msg"><small>End Date is required</small><br /></div>
                            <div v-if="submit && !e.validDate" class="error-msg">
                              <small>Enter Valid Date Range</small>
                            </div>
                          </div>
                        </div>
                        <div @click="e.till_present = 'N'" v-else class="form-control present-div">Present</div>
                      </div>

                      <!--  -->
                      <div>
                        <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret right class="dropdown-date">
                          <template #button-content>
                            <img src="/img/arrow-down.7f3fe26b.svg" alt="icon" width="13px" />
                          </template>
                          <b-dropdown-item @click="e.till_present = 'N'">Select Date</b-dropdown-item>
                          <b-dropdown-item @click="e.till_present = 'Y'">Present</b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </div>

                    <!-- <div class="error-msg" :class="classes">
                      <span>{{ errors[0] }}</span>
                      <small>{{
                        e.dateValid ? "" : "Enter Valid Date Range"
                      }}</small>
                    </div>
                  </ValidationProvider> -->
                  </div>
                  <div class="profile-desc">
                    <!-- <ValidationProvider
                    name="description"
                    rules="required|max:200"
                    v-slot="{ errors, classes }"
                  > -->
                    <b-form-textarea class="w-100 mb-0" placeholder="Brief description of the profile" rows="05" max-rows="10" v-model="e.description"> </b-form-textarea>
                    <div v-if="submit && (!e.description || String(e.description).length == 0)" class="error-msg">
                      <small>Description is required</small>
                    </div>
                    <!-- <div class="error-msg" :class="classes">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider> -->
                  </div>
                  <div>
                    <!-- <ValidationProvider
                    name="designation"
                    rules="required"
                    v-slot="{ errors, classes }"
                  > -->
                    <b-form-input placeholder="Your Designation" v-model="e.designation"> </b-form-input>
                    <div v-if="submit && (!e.designation || String(e.designation).length == 0)" class="error-msg">
                      <small>Designation is required</small>
                    </div>
                    <!-- <div class="error-msg" :class="classes">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider> -->
                  </div>
                  <div>
                    <!-- <ValidationProvider
                    name="emp_type"
                    rules="required"
                    v-slot="{ errors, classes }"
                  > -->
                    <v-select class="form-control mb-0" placeholder="Employment Type" v-model="e.employment_type" :options="['Full Time', 'Part Time', 'Remote Time', 'Internship', 'Contract']"></v-select>
                    <div v-if="submit && (!e.employment_type || String(e.employment_type).length == 0)" class="error-msg">
                      <small>Employment Type is required</small>
                    </div>
                    <!-- <div class="error-msg" :class="classes">
                  <span>{{ errors[0] }}</span>
                </div> -->
                    <!-- </ValidationProvider> -->
                  </div>
                </div>
              </div>
              <div class="mx-auto col-md-2">
                <div class="form-actions">
                  <div v-show="counter > 0">
                    <button :disabled="counter == 0" class="btn btn-light--black btn-custom ml-2" @click="deleteExperiance(counter)">-</button>
                  </div>
                  <div class="ml-auto">
                    <button v-if="counter > 0" @click="shiftWorkExperience(counter, counter - 1)" class="btn btn-light--black btn-custom ml-2">
                      <img src="@/assets/app-icons/arrow-up.svg" class="img-fluid" alt="icon" width="15px" />
                    </button>
                    <button @click="shiftWorkExperience(counter, counter + 1)" v-if="counter < exp.length - 1" class="btn btn-light--black btn-custom ml-2">
                      <img src="@/assets/app-icons/arrow-down.svg" class="img-fluid" alt="icon" width="15px" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="add-box">
            <button class="btn btn-add" @click="addExperiance">ADD WORK EXPERIENCE +</button>
          </div>
        </div>
        <div class="py-4 px-2">
          <div class="sub-heading">
            <label>EDUCATION</label>
          </div>
          <div class="user-form">
            <div v-for="(ed, counter) in edu" :key="counter" class="row">
              <div class="col-md-10">
                <div class="edu-form">
                  <div>
                    <b-form-input placeholder="School/University Name" v-model="ed.university_name"> </b-form-input>
                    <div v-if="submit && (!ed.university_name || String(ed.university_name).length == 0)" class="error-msg">
                      <small>University Name is required</small>
                    </div>
                  </div>

                  <div class="grid-date">
                    <div class="date-change">
                      <date-picker v-model="ed.dateRange.startDate" placeholder="Start Date" class="custom-date" @change="validateEduDate(counter)"></date-picker>
                      <div v-if="submit && (!ed.dateRange.startDate || String(ed.dateRange.startDate).length == 0)" class="error-msg">
                        <small>Start Date is required</small>
                      </div>
                      <div v-if="submit && !ed.validDate" class="error-msg">
                        <small>Enter Valid Date Range</small>
                      </div>
                    </div>

                    <div class="date-change">
                      <date-picker v-model="ed.dateRange.endDate" placeholder="End Date" class="custom-date" @change="validateEduDate(counter)"></date-picker>
                      <div v-if="submit && (!ed.dateRange.endDate || String(ed.dateRange.endDate).length == 0)" class="error-msg">
                        <small>End Date is required</small>
                      </div>
                      <div v-if="submit && !ed.validDate" class="error-msg">
                        <small>Enter Valid Date Range</small>
                      </div>
                    </div>
                  </div>

                  <div>
                    <b-form-input placeholder="Degree" v-model="ed.degree"> </b-form-input>
                    <div v-if="submit && (!ed.degree || String(ed.degree).length == 0)" class="error-msg">
                      <small>Degree is required</small>
                    </div>
                  </div>

                  <div class="mt-3"></div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-actions">
                  <div v-show="counter > 0">
                    <button :disabled="counter == 0" class="btn btn-light--black btn-custom ml-2" @click="deleteEducation(counter)">-</button>
                  </div>
                  <div class="ml-auto">
                    <button v-if="counter > 0" @click="shiftEducation(counter, counter - 1)" class="btn btn-light--black btn-custom ml-2">
                      <img src="@/assets/app-icons/arrow-up.svg" class="img-fluid" alt="icon" width="15px" />
                    </button>
                    <button @click="shiftEducation(counter, counter + 1)" v-if="counter < edu.length - 1" class="btn btn-light--black btn-custom ml-2">
                      <img src="@/assets/app-icons/arrow-down.svg" class="img-fluid" alt="icon" width="15px" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="add-box">
            <button class="btn btn-add" @click="addEducation">ADD EDUCATION +</button>
          </div>
        </div>

        <!-- --------------------Certificates -------------------->
        <div class="py-4 px-2">
          <div class="sub-heading">
            <label>CERTIFICATES</label>
          </div>
          <div class="user-form">
            <div v-for="(ed, counter) in certificates" :key="counter" class="row">
              <div class="col-md-10">
                <div class="edu-form">
                  <div>
                    <b-form-input placeholder="Certification Name" v-model="ed.certificate_name"> </b-form-input>
                    <!-- <div
                      v-if="
                        submit &&
                          (!ed.university_name ||
                            String(ed.university_name).length == 0)
                      "
                      class="error-msg"
                    >
                      <small>University Name is required</small>
                    </div> -->
                  </div>

                  <div class="grid-date">
                    <div class="date-change">
                      <date-picker v-model="ed.dateRange.startDate" placeholder="Start Date" class="custom-date"></date-picker>
                      <!-- @change="validateEduDate(counter)" -->
                      <!-- <div
                        v-if="
                          submit &&
                            (!ed.dateRange.startDate ||
                              String(ed.dateRange.startDate).length == 0)
                        "
                        class="error-msg"
                      >
                        <small>Start Date is required</small>
                      </div> -->
                      <div v-if="submit && !ed.validDate" class="error-msg">
                        <small>Enter Valid Date Range</small>
                      </div>
                    </div>

                    <div class="date-change">
                      <date-picker v-model="ed.dateRange.endDate" placeholder="End Date" class="custom-date" @change="validateCertificateDate(counter)"></date-picker>
                      <!-- <div
                        v-if="
                          submit &&
                            (!ed.dateRange.endDate ||
                              String(ed.dateRange.endDate).length == 0)
                        "
                        class="error-msg"
                      >
                        <small>End Date is required</small>
                      </div> -->
                      <div v-if="submit && !ed.validDate" class="error-msg">
                        <small>Enter Valid Date Range</small>
                      </div>
                    </div>
                  </div>

                  <div>
                    <!-- <div
                      v-if="
                        submit && (!ed.field || String(ed.degree).length == 0)
                      "
                      class="error-msg"
                    >
                      <small>Field is required</small>
                    </div> -->
                  </div>

                  <div class="mt-3"></div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-actions">
                  <div v-show="counter > 0">
                    <button :disabled="counter == 0" class="btn btn-light--black btn-custom ml-2" @click="deleteCertificate(counter)">-</button>
                  </div>
                  <div class="ml-auto">
                    <button v-if="counter > 0" @click="shiftCertificate(counter, counter - 1)" class="btn btn-light--black btn-custom ml-2">
                      <img src="@/assets/app-icons/arrow-up.svg" class="img-fluid" alt="icon" width="15px" />
                    </button>
                    <button @click="shiftCertificate(counter, counter + 1)" v-if="counter < edu.length - 1" class="btn btn-light--black btn-custom ml-2">
                      <img src="@/assets/app-icons/arrow-down.svg" class="img-fluid" alt="icon" width="15px" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="add-box">
            <button class="btn btn-add" @click="addCertificate">ADD CERTIFICATE +</button>
          </div>
        </div>

        <!-------------------- /Certificated----------------------- -->
        <div class="user-social border-top px-2 py-4">
          <div class="sub-heading">
            <label>SOCIAL PROFILE</label>
          </div>
          <div class="social-profile">
            <div class="social-profile-grid">
              <div class="d-flex">
                <div class="icon">
                  <i class="fa fa-linkedin"></i>
                </div>
                <b-form-input v-model="social.linkedin" placeholder="Enter Linkedin profile URL"> </b-form-input>
              </div>
              <div class="d-flex">
                <div class="icon">
                  <i class="fa fa-facebook"></i>
                </div>
                <b-form-input placeholder="Enter Facebook ID URL" v-model="social.facebook"> </b-form-input>
              </div>
              <div class="d-flex">
                <div class="icon">
                  <i class="fa fa-instagram"></i>
                </div>
                <b-form-input placeholder="Enter Instagram profile URL" v-model="social.instagram"> </b-form-input>
              </div>
              <div class="d-flex">
                <div class="icon">
                  <i class="fa fa-twitter"></i>
                </div>
                <b-form-input placeholder="Enter Twitter Handle" v-model="social.twitter"> </b-form-input>
              </div>
            </div>
            <div style="flex: 0 0 25%">
              <div class="add-social-link" v-if="otherLink.length">
                <div class="d-flex" v-for="(o, index) in otherLink" :key="index">
                  <b-form-input placeholder="Enter Other Link" v-model="otherLink[index]"> </b-form-input>
                  <div class="delete-icon" @click="delOtherLink(index)">
                    <img src="@/assets/app-icons/delete.svg" class="img-fluid" alt="delete-icon" width="15px" style="cursor: pointer" />
                  </div>
                </div>

                <!-- <div class="d-flex" v-if="addSocial">
                <div class="icon"></div>
                <b-form-input
                  placeholder="Enter Other Link"
                  v-model="social.other_link2"
                >
                </b-form-input>
              </div> -->
              </div>
              <div>
                <button @click="addSocialLink()" v-if="otherLink.length <= 1" class="btn btn-add-profile mt-0 ml-lg-3 ml-xl-3">ADD SOCIAL PROFILE URL +</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer border-top">
        <div>
          <button @click="resetOnBoardingOne" class="btn btn-reset font-weight-bold mr-lg-5 mr-xl-5">RESET</button>
          <button class="btn btn-next font-weight-bold ml-5" @click.prevent.stop="ValidateForm">NEXT</button>
        </div>
      </div>

      <b-modal ref="resumeLoading" hide-header hide-footer>
        <div class="text-center">
          <b-spinner label="Loading..." style="width: 8rem; height: 8rem"> </b-spinner>
          <h2>Please wait while your details are being auto filled...</h2>
        </div>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import Navigation from "./Navigation.vue";
import DateRangePicker from "vue2-daterange-picker";
import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";
import Vue from "vue";

//you need to import the CSS manually (in case you want to override it)
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import ApplicantProfileService from "@/apiServices/ApplicantProfileService";
import RadialProgressBar from "vue-radial-progress";
import moment from "moment";

// let prevRoute = {};
export default {
  components: {
    Navigation,
    DateRangePicker,
    Datepicker,
    Multiselect,
    RadialProgressBar,
  },
  data() {
    return {
      dateCondition: new Date(),
      submit: false,
      completedSteps: 0,
      prefilledFlag: null,
      totalSteps: 100,
      addSocial: false,
      profile: {},
      cv: null,
      ex: true,
      ed: true,
      cvName: "",
      fileSelected: false,

      dateValid: true,
      till_present: "N",
      cvUploaded: false,
      certificates: [
        {
          certificate_id: null,
          certificate_name: null,
          dateRange: {
            startDate: "",
            endDate: "",
          },
          field: null,
          validDate: true,
        },
      ],
      exp: [
        {
          experiance_id: null,
          org_name: null,
          dateRange: {
            startDate: "",
            endDate: "",
          },
          till_present: "N",
          description: null,
          designation: null,
          employment_type: null,
          experiance_domain: null,
        },
      ],
      edu: [
        {
          education_id: null,
          university_name: null,
          dateRange: {
            startDate: "",
            endDate: "",
          },
          degree: null,
        },
      ],

      social: {
        linkedin: null,
        instagram: null,
        facebook: null,
        twitter: null,
      },
      otherLink: [],
    };
  },
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     prevRoute = from;
  //   });
  // },

  beforeMount() {
    //this.prefilledDataFromStore();
    console.log("inbeforemount :>> ");
  },

  mounted: function () {
    console.log("this.$router :>> ", this.$router);
    console.log("this.$route :>> ", this.$route);

    this.prefilledData();
    this.$nextTick(function (vm) {
      this.validateAllExpDate();
    });
  },
  computed: {
    formComplete() {
      let steps = 0;
      if (this.cvUploaded) {
        steps = 20;
      }
      if (this.exp.length > 0 && this.exp[0].org_name != "") {
        steps = steps + 10;
      }
      if (this.edu.length > 0 && this.edu[0].university_name != "") {
        steps = steps + 10;
      }
      if (this.social.linkedin || this.social.instagram || this.social.facebook || this.social.twitter) {
        steps = steps + 10;
      }
      console.log("steps :>> ", steps);
      this.completedSteps = steps;
      return steps;
    },
  },
  beforeRouteLeave(to, from, next) {
    console.log("to:::::::::::: :>> ", to);
    console.log("from::::::::::: :>> ", from);
    if (to.name != "Applicant-Onboarding-2" && to.name != "Applicant-Onboarding-3" && to.name != "Applicant-Onboarding-4") {
      this.$store.commit("RESET_PREFILLED_FLAG");
      this.$store.commit("RESET_PREFILLED_FLAG2");
      next();
    } else next();
  },
  methods: {
    resetOnBoardingOne() {
      this.exp.map((e) => {
        e.experiance_id = null;
        e.org_name = null;
        e.dateRange = {
          startDate: "",
          endDate: "",
        };
        e.description = null;
        e.designation = null;
        e.employment_type = null;
        e.experiance_domain = null;
      });

      this.edu.map((ed) => {
        ed.education_id = null;
        ed.university_name = null;
        (ed.dateRange = {
          startDate: "",
          endDate: "",
        }),
          (ed.degree = null);
      });

      this.social = {
        linkedin: null,
        instagram: null,
        facebook: null,
        twitter: null,
      };
    },
    delOtherLink(i) {
      this.otherLink.splice(i, 1);
    },
    addSocialLink() {
      if (this.otherLink.length < 2) {
        this.otherLink.push("");
      }
    },
    shiftWorkExperience(from, to) {
      let temp = this.exp[from];
      this.exp[from] = this.exp[to];
      this.exp[to] = temp;
      this.$forceUpdate();
    },
    shiftEducation(from, to) {
      let temp = this.edu[from];
      this.edu[from] = this.edu[to];
      this.edu[to] = temp;
      this.$forceUpdate();
    },
    shiftCertificate(from, to) {
      let temp = this.certificates[from];
      this.certificates[from] = this.certificates[to];
      this.certificates[to] = temp;
      this.$forceUpdate();
    },
    testFileChange(e) {
      console.log("e :>> ", e);
    },
    showModal() {
      this.$refs["resumeLoading"].show();
    },
    hideModal() {
      this.$refs["resumeLoading"].hide();
    },
    onReloadResume() {
      const parser_id = this.parser_id;
      ApplicantProfileService.getUploadCv({ parser_id })
        .then((res) => {
          
          let parserData = res.data.data;
          this.setDataFromParser(parserData);
          this.prefilledData();
        })
        .catch((err) => {
          
          this.$toasted.show("Please Upload Your resume", {
            duration: 6000,
            icon: "clear",
          });
        });
    },
    onDeleteResume() {
      ApplicantProfileService.deleteMainCv()
        .then((res) => {
          
          this.prefilledData(true);
          this.$toasted.show("Resume deleted", {
            duration: 6000,
            icon: "check",
          });
        })
        .catch((err) => {
          
          this.$toasted.show("Error while resume delete", {
            duration: 6000,
            icon: "clear",
          });
        });
    },

    async getResumeParserData() {
      try {
        const user_id = this.$cookies.get("user_id");
        const getParser = await ApplicantProfileService.getUploadCv({
          user_id,
        });
        this.cv = getParser.data.data.cv.user_job_cv_path;
        this.cvName = getParser.data.data.cv.user_job_cv_name;
        this.exp = getParser.data.data.parser.workExperience.map((m) => {
          return {
            experiance_id: null,
            org_name: m.organization,
            dateRange: {
              startDate: moment(m?.dates.startDate).format("YYYY-MM-DD HH:mm:ss"),
              endDate: moment(m?.dates.endDate).format("YYYY-MM-DD HH:mm:ss"),
            },
            description: m.jobDescription,
            designation: m.jobTitle,
            employment_type: null,
            experiance_domain: null,
          };
        });
        this.edu = getParser.data.data.parser.education.map((m) => {
          return {
            education_id: null,
            university_name: m.organization,
            degree: m.accreditation.education,
            dateRange: {
              startDate: "",
              endDate: moment(m.dates.completionDate).format("YYYY-MM-DD HH:mm:ss"),
            },
          };
        });
      } catch (error) {
        
      }
    },

    prefilledDataFromStore() {
      let loader = this.$loading.show();
      let profile = {
        exp: this.$store.getters.getApplicantExperiance,
        edu: this.$store.getters.getApplicantEducation,
        social: JSON.stringify(this.$store.getters.getApplicantSocialLink),
        cert: this.$store.getters.getApplicantCertificates,
      };
      const other = this.$store.getters.getApplicantOtherSocialLink;
      this.otherLink = other;
      let social = JSON.parse(profile.social);
      console.log("social :>> ", social);
      this.social = social;

      console.log("this.social :>> ", this.social);
      if (profile.cert.length) {
        this.certificates = profile.cert.map((m) => {
          return {
            ...m,
            dateRange: {
              startDate: m.dateRange.startDate ? new Date(m.dateRange.startDate) : "",
              endDate: m.dateRange.endDate ? new Date(m.dateRange.endDate) : "",
            },
          };
        });
      }
      const exp = profile.exp.map((x) => {
        return {
          experiance_id: x.experiance_id,
          org_name: x.org_name,
          dateRange: {
            startDate: x.dateRange.startDate ? new Date(x.dateRange?.startDate) : null,
            endDate: x.dateRange.endDate ? new Date(x.dateRange?.endDate) : null,
          },
          till_present: x.till_present && x.till_present == "Y" ? "Y" : "N",
          description: x.description,
          designation: x.designation,
          employment_type: x.employment_type,
          validDate: true,
        };
      });
      const edu = profile.edu.map((x) => {
        return {
          education_id: x.education_id,
          university_name: x.university_name,
          dateRange: {
            startDate: new Date(x.dateRange?.startDate),
            endDate: new Date(x.dateRange?.endDate),
          },
          degree: x.degree,
          validDate: true,
        };
      });
      console.log("this.$store.getters.getApplicantCv:: :>> ", this.$store.getters.getApplicantCv);
      console.log("this.$store.getters.getApplicantCvName:::: :>> ", this.$store.getters.getApplicantCvName);
      this.cv = this.$store.getters.getApplicantCv;
      this.cvName = this.cv ? this.$store.getters.getApplicantCvName : "Upload Your Cv";
      this.exp = exp;
      this.edu = edu;
      loader.hide();
    },
    validateAllExpDate() {
      this.exp.forEach((ex) => {
        let start = ex.dateRange.startDate;
        let end = ex.dateRange.endDate;
        // this.validateDate(start, end);
      });
    },

    prefilledData(getFromBackEnd) {
      if (this.$store.getters.getPrefilledFlag == 1 && !getFromBackEnd) {
        this.prefilledDataFromStore();
      } else {
        let loader = this.$loading.show();
        console.log("hitting prefilled data");
        const payload = { applicant_id: this.$cookies.get("user_id") };
        ApplicantProfileService.getApplicantProfile(payload).then((data) => {
          let profile = data.data.data;
          
          this.cv = profile.user_cv.length > 0 ? profile.user_cv[0].user_job_cv_path : null;
          this.cvName = this.cv ? profile.user_cv[0].user_job_cv_name : "Upload Your Cv";

          console.log("this.cvName :>> ", this.cvName);
          const expFromStore = profile.user_experience;
          // org_name: null,
          //           dateRange: {
          //             startDate: "",
          //             endDate: "",
          //           },
          //           description: null,
          //           designation: null,
          //           employment_type: null,
          const exp = expFromStore.map((x) => {
            return {
              experiance_id: x.experiance_id,
              org_name: x.experiance_working_org,
              dateRange: {
                startDate: x.experiance_start ? new Date(x.experiance_start) : null,
                endDate: x.experiance_end ? new Date(x.experiance_end) : null,
              },
              till_present: x.till_present && x.till_present == "Y" ? "Y" : "N",
              description: x.description,
              designation: x.designation,
              employment_type: x.employment_type,
              validDate: true,
            };
          });

          const eduFromStore = profile.user_education;
          const edu = eduFromStore.map((x) => {
            return {
              education_id: x.education_id,
              university_name: x.education_university_name,
              dateRange: {
                startDate: x.education_start_year ? new Date(x.education_start_year) : null,
                endDate: x.education_passout_year ? new Date(x.education_passout_year) : null,
              },
              degree: x.education_degree,

              validDate: true,
            };
          });
          const cert = profile.user_certificates;
          console.log("exp :>> ", exp);
          console.log("edu :>> ", edu);
          if (cert && cert.length) {
            this.certificates = cert.map((m) => {
              return {
                certificate_id: m.certificate_id,
                certificate_name: m.c_name,
                field: m.c_field,
                dateRange: {
                  startDate: m.start_date ? new Date(m.start_date) : "",
                  endDate: m.end_date ? new Date(m.end_date) : "",
                },
                validDate: true,
              };
            });
          }
          if (exp.length > 0 && edu.length > 0) {
            this.exp = exp;
            this.edu = edu;
          } else {
            this.exp = [
              {
                experiance_id: null,
                org_name: null,
                dateRange: {
                  startDate: "",
                  endDate: "",
                },
                description: null,
                designation: null,
                employment_type: null,
                experiance_domain: null,
                till_present: "N",
              },
            ];
            this.edu = [
              {
                education_id: null,
                university_name: null,
                dateRange: {
                  startDate: "",
                  endDate: "",
                },
                degree: null,
              },
            ];
            //this.prefilledDataFromStore();
            // this.getResumeParserData();
          }
          const { instagram, facebook, twitter, linked_in, other_link1, other_link2 } = profile.user_data[0];

          this.social = {
            instagram: instagram,
            facebook: facebook,
            twitter: twitter,
            linkedin: linked_in,
          };
          if (other_link1) {
            this.otherLink.push(other_link1);
          }
          if (other_link2) {
            this.otherLink.push(other_link2);
          }

          this.$forceUpdate();
          loader.hide();
          this.validateAllExpDate();
        });
      }
    },

    validateExpDate(i) {
      let valid = true;
      console.log("i :>> ", i);

      console.log("exp[i] :>> ", this.exp[i]);
      let s = this.exp[i].dateRange.startDate;
      let e = this.exp[i].dateRange.endDate;
      if (s != "Invalid date" && e != "Invalid date") {
        if (moment(s).isAfter(e)) {
          valid = false;
          this.exp[i].validDate = false;
        } else {
          valid = true;
          this.exp[i].validDate = true;
        }
      }
    },
    validateCertificateDate(i) {
      let valid = true;
      console.log("i :>> ", i);

      let s = this.certificates[i]?.dateRange.startDate;
      let e = this.certificates[i]?.dateRange.endDate;
      if (s != "Invalid date" && e != "Invalid date") {
        if (moment(s).isAfter(e)) {
          valid = false;
          if (this.certificates[i]) {
            this.certificates[i].validDate = false;
          }
        } else {
          valid = true;
          if (this.certificates[i]) {
            this.certificates[i].validDate = true;
          }
        }
      }
    },
    validateEduDate(i) {
      let valid = true;
      console.log("i :>> ", i);

      let s = this.edu[i]?.dateRange.startDate;
      let e = this.edu[i]?.dateRange.endDate;
      if (s != "Invalid date" && e != "Invalid date") {
        if (moment(s).isAfter(e)) {
          valid = false;
          if (this.edu[i]) {
            this.edu[i].validDate = false;
          }
        } else {
          valid = true;
          if (this.edu[i]) {
            this.edu[i].validDate = true;
          }
        }
      }
    },

    validateExpDateRange() {
      let valid = false;
      return new Promise((resolve, reject) => {
        valid = this.exp.every((v) => {
          console.log("v :>> ", v);
          if (!v.till_present || v.till_present == "N") {
            if (moment(v.dateRange.endDate).isAfter(v.dateRange.startDate)) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        });
        resolve(valid);
      });
    },

    validateEduDateRange() {
      let valid = false;
      return new Promise((resolve, reject) => {
        valid = this.edu.every((v) => {
          if (moment(v.dateRange.endDate).isAfter(v.dateRange.startDate)) {
            return true;
          } else {
            return false;
          }
        });
        resolve(valid);
      });
    },
    validateCertDate() {
      let valid = false;
      return new Promise((resolve, reject) => {
        if (this.certificates.some((s) => s.validDate == false)) {
          valid = false;
        } else {
          valid = true;
        }
        resolve(valid);
      });
    },
    ValidateExperiance() {
      let valid = true;

      return new Promise((resolve, reject) => {
        let dateValid = this.exp.every((v) => {
          if (v.dateValid) {
            return true;
          } else {
            return false;
          }
        });
        this.exp.map((ex) => {
          let design = ex.designation && String(ex.designation).length != 0;
          let startDate = ex.dateRange.startDate && String(ex.dateRange.startDate).length != 0;
          let endDate = ex.dateRange.endDate && String(ex.dateRange.endDate).length != 0;
          let empType = ex.employment_type ? false : false;
          let desc = ex.description && String(ex.description).length != 0;

          console.log("design, startDate, endDate, emtType,desc :>> ", design, startDate, endDate, ex.employment_type, desc);
          console.log("dateValid :>> ", dateValid);
          if (
            !ex.org_name ||
            String(ex.org_name).length == 0 ||
            !ex.employment_type ||
            !ex.designation ||
            String(ex.designation).length == 0 ||
            !ex.description ||
            String(ex.description).length == 0 ||
            !ex.employment_type ||
            !ex.dateRange.startDate ||
            String(ex.dateRange.startDate).length == 0 ||
            ((!ex.till_present || ex.till_present == "N") && (!ex.dateRange.endDate || String(ex.dateRange.endDate).length == 0))
          ) {
            valid = false;

            console.log("ex.org_name :>> ", ex.org_name);
            // resolve(true);
          }
        });
        resolve(valid);
      });
    },
    async ValidateEducation() {
      let valid = true;

      return new Promise((resolve, reject) => {
        this.edu.map((ed) => {
          let university_name = !ed.university_name || String(ed.university_name).length == 0;

          let degree = !ed.degree || String(ed.degree).length == 0;
          let startDate = !ed.dateRange.startDate;
          let endDate = !ed.dateRange.endDate;

          console.log(
            "university_name, degree :>> ",
            university_name,

            degree
          );
          let dateValid = this.edu.every((v) => {
            if (v.dateValid) {
              return true;
            } else {
              return false;
            }
          });
          console.log("dateValid :>> ", dateValid);
          if (university_name || degree || startDate || endDate) {
            valid = false;

            // resolve(true);
          }
        });
        resolve(valid);
      });

      // let validEduForm = this.edu.every((v) => {
      //   if (
      //     v.university_name != "" &&
      //     v.degree != "" &&
      //     v.field != "" &&
      //     v.dateRange.startDate != "Invalid" &&
      //     v.dateRange.endDate != "Invalid"
      //   ) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // });
      // console.log("validEduForm :>> ", validEduForm);
      // return this.$refs.education
      //   .validate()
      //   .then((result) => {
      //     if (result && validEduForm) {
      //       console.log("succes");
      //       this.ed = true;
      //       this.SubmitEducation();
      //       return true;
      //     } else {
      //       this.ed = false;
      //       this.$toasted.show(
      //         "Some Fields are missing in Education section!",
      //         {
      //           position: "top-right",
      //           duration: 6000,
      //           icon: "clear",
      //         }
      //       );
      //       throw new Error("invalid form");
      //     }
      //   })
      //   .catch((err) => {
      //     console.log("err validation:>> ", err);
      //   });
    },

    async ValidateForm() {
      try {
        this.submit = true;
        let validateExpDateRange = await this.validateExpDateRange();
        let validateEduDateRange = await this.validateEduDateRange();
        let validateCertiDateRange = await this.validateCertDate();

        let expPromise = await this.ValidateExperiance();
        let eduPromise = await this.ValidateEducation();
        console.log("validateCertiDateRange", validateCertiDateRange);
        // this.validateEduDate();
        // this.validateExpDate();

        // console.log("eduValid :>> ", eduValidDate);
        // console.log("expValidDate :>> ", expValidDate);
        // console.log("expPromise :>> ", expPromise);
        // console.log("eduPromise :>> ", eduPromise);
        console.log("validateExpDateRange :>> ", validateExpDateRange);
        console.log("validateEduDateRange :>> ", validateEduDateRange);

        if (expPromise && eduPromise && validateExpDateRange && validateEduDateRange && validateCertiDateRange) {
          this.$store.commit("SET_PREFILLED_FLAG");
          this.CvAddStore();
          this.SubmitExperiance();
          this.SubmitEducation();
          this.SubmitCertificate();

          this.SubmitSocialLink();
          console.log("this.completedSteps :>> ", this.completedSteps);
          const payload = { steps: this.completedSteps };
          this.$store.dispatch("onHandleCompletedSteps", payload);

          // if (
          //   this.edu.every((e) => e.dateValid === true) &&
          //   this.exp.every((e) => e.dateValid === true)
          // ) {
          this.$router.push({ name: "Applicant-Onboarding-2" });
          return true;
          // } else {
          //   this.$toasted.show("Enter Correct Date Range!", {
          //     duration: 6000,
          //     icon: "clear",
          //   });
          // }
        } else {
          this.$toasted.show("There are some fields missing", {
            duration: 6000,
            icon: "clear",
          });
        }
      } catch (err) {
        console.log("err --------------:>> ", err);
        return false;
      }
    },
    // updateSocialLink(value, property) {
    //   console.log("value :>> ", value);
    //   console.log("property :>> ", property);
    //   this.$store.commit("SET_SOCIAL_LINK_OBJ", { property, value });
    // },
    CvAddStore() {
      const payload = { cv: this.cv, cvName: this.cvName };
      this.$store.dispatch("onHandleApplicantCv", payload);
    },
    uploadNewResume(event) {
      const file = event.target.files[0];
      console.log("file :>> ", event);
      this.cv = file;
      this.fileSelected = true;
      // const payload = { cv: file };
      if ((file && file.type == "application/pdf") || (file && file.type == "application/msword") || (file && file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
        if (file.size >= 1000000) {
          this.$toasted.show("pdf file should be less then 1mb", {
            duration: 6000,
            icon: "clear",
          });
          return;
        }
        this.showModal();
        //this.$store.dispatch("onHandleApplicantCv", payload);
        const user_id = this.$cookies.get("user_id");

        //const data = file.getAsBinary();
        const form = new FormData();
        form.append("resume", event.target.files[0]);
        form.append("user_id", user_id);

        ApplicantProfileService.uploadCv(form)
          .then((res) => {
            // let { identifier } = res.data.data;
            // this.parser_id = identifier;
            this.cvSelected = true;
            // this.getResumeParserData();
            console.log("res.data.data ------------:::>> ", res.data.data);
            let parserData = res.data.data;
            this.parser_id = parserData.parser.meta.identifier;
            this.cvUploaded = true;
            this.setDataFromParser(parserData);

            this.hideModal();
          })
          .catch((error) => {
            
            this.cvUploaded = false;
            this.hideModal();
            this.$toasted.show("Error while uploading Resume", {
              duration: 6000,
              icon: "clear",
            });
          });
      } else {
        this.$toasted.show("Please select pdf/word file", {
          duration: 6000,
          icon: "clear",
        });
        this.hideModal();
      }
    },
    onFileChange(event) {
      if (!this.cv) {
        this.uploadNewResume(event);
      } else {
        // this.$toasted.show("Resume is already uploaded", {
        //   duration: 6000,
        //   icon: "clear",
        // });
        // return;

        this.$bvModal
          .msgBoxConfirm("Uploading a new CV would overwrite the existing details. Are you sure you want to proceed?", {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              let loader = this.$loading.show();
              ApplicantProfileService.deleteMainCv()
                .then((res) => {
                  
                  loader.hide();
                  this.uploadNewResume(event);
                })
                .catch((err) => {
                  
                  this.$toasted.show("Error while uploading new resume", {
                    duration: 6000,
                    icon: "clear",
                  });
                });
            }
          })
          .catch((err) => {
            
          });
      }
    },
    async setDataFromParser(getParser) {
      console.log("getParser :>> ", getParser);
      let parser = getParser.parser.data;
      parser.cv = getParser.cv;
      console.log("parser :>> ", parser);
      // this.profile.user_username = parser.name?.raw;
      // this.profile.user_job_title =
      //   parser.sections[0] && parser.sections[0].text
      //     ? parser.sections[0]?.text.split("\n")[1]
      //     : null;
      // this.profile.user_email =
      //   parser.emails?.length > 0 ? parser.emails[0] : "";
      this.profile.user_job_country_of_residence = parser.location?.country;
      this.profile.user_job_introduction = parser.summary;
      // let length = parser.workExperience.length - 1;
      // this.profile.user_job_current_org =
      //   parser.workExperience[length]?.organization;
      //this.profile.skills = parser.skills;
      this.profile.user_job_cv_path = parser.cv.user_job_cv_path;
      this.profile.user_job_cv_name = this.profile.user_job_cv_path ? this.profile.user_job_cv_path.split("/")[5].split("_")[1] : "";

      //this.job_title = this.profile.sections[0].text.split("\n")[1];

      const payload = { applicant_id: this.$cookies.get("user_id") };
      const ap = await ApplicantProfileService.getApplicantProfile(payload);
      let appProfile = ap.data.data;
      const postData = new FormData();
      // const par = getParser.data.data.parser;

      // const exp_id = appProfile.user_experience.map(({ experiance_id }) => {
      //   return experiance_id;
      // });
      // const edu_id = appProfile.user_education.map(({ education_id }) => {
      //   return education_id;
      // });

      let exp = parser.workExperience.map((m) => {
        return {
          description: "",
          designation: m.jobTitle,
          org_name: m?.organization,
          dateRange: {
            startDate: m.dates && m.dates.startDate ? moment(m.dates.startDate).format("YYYY-MM-DD") : null,
            endDate: m.dates && m.dates.endDate ? moment(m.dates.endDate).format("YYYY-MM-DD") : null,
          },
          description: m.jobDescription,
        };
      });
      // exp_id.forEach((id, i) => {
      //   if (exp.length > i) {
      //     exp[i].experiance_id = id;
      //   }
      // });
      // exp_id = exp_id.filter(f => {f == exp})
      let edu = parser.education.map((m) => {
        return {
          degree: m.accreditation && m.accreditation.education ? m.accreditation.education : null,
          university_name: m?.organization,
          dateRange: {
            startDate: null,
            endDate: m.dates && m.dates.completionDate ? moment(m.dates.completionDate).format("YYYY-MM-DD") : null,
          },
        };
      });
      // edu_id.forEach((id, i) => {
      //   if (edu.length > i) {
      //     edu[i].education_id = id;
      //   }
      // });
      const pd = {
        country: parser.location?.country,
        nationality: parser.location?.country,
      };
      let cj = {
        introduction: parser.summary,
        skills: parser.skills.map((m) => {
          return {
            label: m,
            skill_id: null,
          };
        }),
        delSkills: [],
      };
      if (cj.skills.length > 10) {
        cj.skills.length = 10;
      }

      let social = {
        linkedin: null,
        instagram: null,
        facebook: null,
        twitter: null,
      };

      if (parser.websites && parser.websites.length > 0) {
        parser.websites.map((x) => {
          if (x.includes("linkedin")) {
            social.linkedin = x;
          } else if (x.includes("instagram")) {
            social.instagram = x;
          } else if (x.includes("facebook")) {
            social.facebook = x;
          } else if (x.includes("twitter")) {
            social.twitter = x;
          }
        });
      }

      console.log("edu, exp, social :>> ", edu, exp, social);

      // if (
      //   appProfile.user_experience.length <= 1 ||
      //   appProfile.user_education.length <= 1
      // ) {
      postData.append("experiance", JSON.stringify(exp));
      // postData.append("personalDetails", JSON.stringify(pd));
      postData.append("education", JSON.stringify(edu));
      postData.append("currentJob", JSON.stringify(cj));
      postData.append("socialLink", JSON.stringify(social));

      console.log("postData :>> ", postData);

      const ApplicantProfile = await ApplicantProfileService.updateApplicantProfile(postData, this.user_id);

      this.prefilledData(true);

      // this.getProfile();
      // }
    },

    dateFormat(classes, date) {
      if (!classes.disabled) {
        classes.disabled = date.getTime() < new Date();
      }
      return classes;
    },
    addExperiance() {
      this.exp.push({
        org_name: null,
        dateRange: {
          startDate: null,
          endDate: null,
        },
        till_present: "N",
        description: null,
        designation: null,
        employment_type: null,
      });
      //console.log("this.exp :>> ", this.exp);
      console.log("this.dateRange :>> ", this.dateRange);
    },
    deleteExperiance(counter) {
      if (this.exp.length !== 1) {
        this.exp.splice(counter, 1);
      }
      return;
    },
    addEducation() {
      this.edu.push({
        university_name: null,
        dateRange: {
          startDate: null,
          endDate: null,
        },
        degree: null,
      });
      // console.log("this.exp :>> ", this.exp);
    },
    deleteEducation(counter) {
      if (this.edu.length !== 1) {
        this.edu.splice(counter, 1);
      }
      return;
    },
    addCertificate() {
      this.certificates.push({
        certificate_name: null,
        dateRange: {
          startDate: "",
          endDate: "",
        },
        field: null,
        validDate: true,
      });
    },
    deleteCertificate(counter) {
      if (this.certificates.length !== 1) {
        this.certificates.splice(counter, 1);
      }
      return;
    },
    SubmitSocialLink() {
      const payload = this.social;
      this.$store.dispatch("onHandleApplicantSocialLink", payload);
      const p = this.otherLink;
      this.$store.commit("SET_OTHER_LINK", p);
    },
    SubmitExperiance() {
      // this.exp.push(this.experiance);
      const payload = { experiance: this.exp };
      
      this.$store.dispatch("onHandleApplicantExperianceState", payload);

      return true;
    },
    SubmitEducation() {
      const payload = { education: this.edu };
      console.log("this.edu :>> ", payload);
      this.$store.dispatch("onHandleApplicantEducationState", payload);
      return true;
    },
    SubmitCertificate() {
      const payload = { certificate: this.certificates };
      this.$store.commit("ADD_CERTIFICATE", payload);
      return true;
    },
  },
};
</script>

<style>
.grid-date .error-msg,
.date-change .error-msg {
  margin-top: 0px !important;
}
.add-social-link {
  flex: 0 0 25%;
  margin-left: 18px;
}
.delete-icon {
  /* margin-left: -16px;
  margin-top: 0px; */
  margin-left: -28px;
  margin-top: 11px;
}
.btn-present {
  background: #eaeaea !important;
  color: black;
  border-left: 1px solid #a2a2a2 !important;
  border-radius: 0px 08px 08px 0px;
  font-size: 12px !important;
  letter-spacing: 1px;
  text-align: left;
  height: 45px;
  padding: 0 08px;
}
.present-div {
  height: 45px !important;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* color: #A2A2A2; */
  font-size: 15px;
}
.end-date .mx-input {
  border-radius: 04px 0px 0px 04px !important;
}

.dropdown-date .dropdown-item:active {
  color: black !important;
}

.dropdown-date .btn {
  background: #eaeaea;
  height: 45px;
  margin-left: -3px;
  padding: 0 11px 0 0;
}

.dropdown-date#dropdown-1:after {
  background-image: url("../../assets/app-icons/arrow-down.svg") !important;
  background-size: 10px 20px;
  display: inline-block;
  width: 10px;
  height: 20px;
  content: "";
  filter: brightness(0%);
  position: absolute;
  right: 6px;
  top: 12px;
}

.dropdown-date .btn-secondary:not(:disabled):not(.disabled):active,
.dropdown-date .btn-secondary:not(:disabled):not(.disabled).active,
.dropdown-date.show > .btn-secondary.dropdown-toggle {
  z-index: 0 !important;
  background: #eaeaea !important;
}

.dropdown-date .btn:focus {
  z-index: 0 !important;
}

.grid-end-date {
  display: grid;
  grid-template-columns: 91% 10%;
}
</style>
