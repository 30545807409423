<template>
  <b-row class="py-5">
    <button class="btn-light--black btn btn-custom__one px-4 py-2">TIPS</button>
    <b-col class="offset-4 col-md-4 flex-between">
      <button class="btn-light--black btn btn-custom__one px-4 py-2" @click="previous" v-show="!isFirst()">PREVIOUS</button>
      <button class="btn-primary btn btn-custom__one px-4 py-2" @click="next">
        {{ nextButtonText() }}
      </button>
    </b-col>
  </b-row>
</template>

<script>
import ApplicantProfileService from "@/apiServices/ApplicantProfileService";

export default {
  name: "Navigation",
  props: {
    formOne: Function,
    formTwo: { type: Function },
  },
  data() {
    return {
      currentStep: null,
      user_id: this.$cookies.get("user_id"),
    };
  },
  mounted() {},

  methods: {
    nextButtonText() {
      if (this.$route.name === "Applicant-Onboarding-3") {
        return "Submit";
      }

      return "Next";
    },
    isFirst() {
      return this.$route.name === "Applicant-Onboarding-1";
    },
    async next() {
      if (this.$route.name === "Applicant-Onboarding-3") {
        this.submit();
      } else {
        console.log("this.currentStep :>> ", this.$store.getters.getNavigationState);
        this.currentStep = this.$store.getters.getNavigationState;
        if (this.currentStep === 1) {
          const s = await this.formOne();
          console.log("this.formOne() :>> ", s);
          if (s) {
            this.$router.push("/Applicant-Onboarding-" + ++this.currentStep);
          }
        }
        if (this.currentStep === 2) {
          const s = await this.formTwo();
          console.log("second Form :>> ", s);
          if (s) {
            this.$router.push("/Applicant-Onboarding-" + ++this.currentStep);
          }
        }
      }
    },
    previous() {
      if (this.$route.name === "Applicant-Onboarding-3") {
        this.$router.push("Applicant-Onboarding-2");
      } else {
        this.$router.push("Applicant-Onboarding-1");
      }
    },
    async submit() {
      const exp = this.$store.getters;
      console.log("userId-->", this.user_id);

      const postData = new FormData();

      let profile = {
        cv: exp.getApplicantCv,
        experiance: [...exp.getApplicantExperiance],
        education: [...exp.getApplicantEducation],
        socialLink: JSON.stringify(JSON.stringify({ ...exp.getApplicantSocialLink })),
        personalDetails: { ...exp.getApplicantPersonalDetails },
        currentJob: { ...exp.getApplicantCurrentJob },
      };
     

      if (!profile.cv || !profile.personalDetails.photo) {
        alert("Please add Resume and Photo");
        return;
      }
      postData.append("resume", profile.cv, "cv.pdf");
      postData.append("experiance", JSON.stringify(profile.experiance));
      postData.append("education", JSON.stringify(profile.education));
      postData.append("socialLink", JSON.stringify(profile.socialLink));
      postData.append("personalDetails", JSON.stringify(profile.personalDetails));
      postData.append("image", profile.personalDetails.photo);
      postData.append("currentJob", JSON.stringify(profile.currentJob));


      const ApplicantProfile = await ApplicantProfileService.updateApplicantProfile(postData, this.user_id);
      // ).then((res) => {
      //   console.log(res);
      //   alert("Your form Submitted successfully!");
      // });
      const { user_id, subs_pkg_id } = this.$store.getters.getSubsPkgPlan;
      const subsDetails = { user_id: user_id, subs_pkg_id: subs_pkg_id };
      console.log("subsDetails :>> ", subsDetails);

      console.log("subs :>> ", subs);
      if (subs.status) {
        this.$toasted.show("Your Profile Successfully Submitted!", {
          position: "top-right",
          duration: 6000,
        });
        this.$router.push({ name: "Applicant" });
      }
    },
  },
};
</script>

<style></style>
